import React from "react";
import ReactDOM from "react-dom";
import { Input, Button } from "semantic-ui-react";
import { duration } from "moment";

import "./styles.css";
import "semantic-ui-css/semantic.min.css";

class App extends React.Component {
  state = {
    duration: "",
    durationParsed: []
  };

  onGetDuration = (_, { value }) => {
    this.setState({
      duration: value
    });
  };

  parseDuration = () => {
    if (this.state.duration) {
      this.setState({
        durationParsed: [
          `${duration(this.state.duration).humanize()} `,
          `${duration(this.state.duration).asMilliseconds()} ms `,
          `${duration(this.state.duration).asSeconds()} sec `,
          `${duration(this.state.duration).asMinutes()} min `
        ]
      });
    } else {
      this.setState({
        durationParsed: []
      });
    }
  };

  render() {
    return (
      <div className="App">
        <h1>Convert your ISO8601 duration into Human readable unit</h1>
        <Input placeholder="Your duration" onChange={this.onGetDuration} />
        <Button onClick={this.parseDuration}>Convert!</Button>
        {this.state.durationParsed.length > 0 && (
          <div className="result">
            {this.state.durationParsed.map((duration, i) => (
              <p key={i}>{duration}</p>
            ))}
          </div>
        )}
      </div>
    );
  }
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
